let app = {};
import scrollLock from "../utils/scroll-lock";
app.forms = {
	init: function () {
		let self = this;

		if (typeof Marionette !== "undefined") {
			let formSubmitController = Marionette.Object.extend({
				initialize: function () {
					this.listenTo(
						Backbone.Radio.channel("forms"),
						"submit:response",
						this.actionSubmit
					);
				},
				actionSubmit: function (response) {
					let formID = response.data.form_id;

					setTimeout(function () {
						self.ninjaFormsInit( "#nf-form-" + formID + "-cont" );
					}, 300);
				},
			});
			new formSubmitController();
		}
	},
	ninjaFormsInit: function (formElementID) {
		let self = this;

		let $form = $(formElementID),
		$inputs = $form.find("input:not([type=button]), textarea, select");

		$inputs.filter('[type="tel"]').attr('role', 'presentation');
		
		$inputs.on("focus", function () {
			let $this = $(this),
				$fieldWrap = $this.closest(".field-wrap"),
				$labelWrap = $fieldWrap.find(".nf-field-label"),
				$label = $labelWrap.find("label");
			$labelWrap.addClass("active focused");
		});

		$inputs.on("blur", function () {
			let $this = $(this),
				$fieldWrap = $this.closest(".field-wrap"),
				$labelWrap = $fieldWrap.find(".nf-field-label"),
				$label = $labelWrap.find("label");
			if ($this.val()) {
				$labelWrap.removeClass("focused");
			} else {
				$labelWrap.removeClass("focused active");
			}
		});

		self.ninjaFormsInitSelects(formElementID);
	},
	ninjaFormsInitSelects: function (formID) {
		let $form = $(formID),
			$selects = $form.find("select");

		if ($selects.length > 0) {
			$selects.each(function (i, item) {
				let $select = $(item),
					$placeholder = $select.find("option:first-child"),
					placeholderText = $placeholder.text();

				$placeholder.attr("value", "").text("");
			});
		}
	},
};

window.addEventListener('DOMContentLoaded', (event) => {
    app.forms.init();
});

$(document).on("nfFormReady", function (e, ninjaform) {
	let formElementID = ninjaform.el;
	app.forms.ninjaFormsInit(formElementID);
});

const responsePopup = $(".form-response"),
successMsg = responsePopup.find(".popup-success"),
successDesc = successMsg.find('.popup-success-message'),
closeModalResponseSuccess = $(".form-response .popup-success .popup__close"),
closeModalResponseError = $(".form-response .popup-error .popup__close"),
popupBgResponseSuccess = $(".form-response .popup-success .popup__bg"),
popupBgResponseError = $(".form-response .popup-error .popup__bg"),
errorMsg = responsePopup.find(".popup-error"),
popupForm = $(".popup--form"),
popupApplication = $(".popup--application"),
popupPosition = $(".popup__position");

$(document).on("nfFormSubmitResponse", function (event, response) {
	const responseData = response.response.data;
	const { redirect, success_message } = responseData.actions;
	if (!redirect) {
		if (response.response.errors.length > 0) {
			successMsg.removeClass("is-active");
			popupForm.removeClass("is-active");
			popupApplication.removeClass("is-active");
			popupPosition.removeClass("is-active");
			errorMsg.addClass("is-active");
			scrollLock.enable();
		} else {
			const forms = $('.nf-form-cont');
			let hash = '#thank-you';

			const formId = responseData.form_id;
			const currentForm = forms.filter(`#nf-form-${formId}-cont`);

			const classList = Array.from(currentForm[0].classList);
			const matchingClass = classList.find((item) => item.startsWith('js-form_'));

			if (matchingClass) {
				hash += `-${matchingClass.split('_')[1]}`;
			}

			history.replaceState(null, document.title, location.pathname + location.search + hash);

			if (success_message) {
				successDesc.html(success_message);
			}

			successMsg.addClass("is-active");
			errorMsg.removeClass("is-active");
			popupForm.removeClass("is-active");
			popupApplication.removeClass("is-active");
			popupPosition.removeClass("is-active");
			scrollLock.enable();
		}
	}
});

closeModalResponseError.on("click", function() {
	hideResponsePopup();
});

closeModalResponseSuccess.on("click", function() {
	hideResponsePopup();
});

popupBgResponseSuccess.on("click", function() {
	hideResponsePopup();
});

popupBgResponseError.on("click", function() {
	hideResponsePopup();
});

$(document).keydown(function(e) {
	const code = e.keyCode || e.which;
	if (code === 27) {
		hideResponsePopup();
	}
});

function hideResponsePopup() {
	successMsg.removeClass("is-active");
	errorMsg.removeClass("is-active");
	scrollLock.disable();

	history.replaceState(null, document.title, location.pathname + location.search);
}